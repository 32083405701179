.validate .navbar-layout {
  display: unset;
}

.validate-header {
  grid-area: header;
}

.validate-main {
  grid-area: main;
  align-content: center;
  text-align: center;
  padding: 80px 10px 0 10px;
}