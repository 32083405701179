.grid-sort-container {
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.grid-sort-container:hover {
  background-color: var(--hover);
}

.grid-sort-container > svg {
  width: 16px;
  height: 16px;
}

.grid-sort-content {
  cursor: default;
  padding: 12px;
  display: flex;
  align-items: stretch;
  min-height: 115px;
}

.grid-sort-content table {
  border: 1px solid gray;
  padding-left: 5px;
  padding-right: 5px;
  border-collapse: collapse;
}

.grid-sort-content table tbody tr td span {
  white-space: nowrap;
}

.grid-sort-content table tr:hover:not(.grid-sort-selected) {
  background-color: var(--hover);
}

.grid-sort-content table tr.grid-sort-selected {
  background-color: var(--light-gray);
}

.grid-sort-btn-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 10px;
}

.grid-sort-btn-container > button {
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;
  width: 30px;
}

.grid-sort-btn-container > button svg {
  opacity: .5;
  fill: black;
}

.grid-sort-btn-container > button:hover svg {
  opacity: 1;
}

.grid-sort-content input[type=checkbox] {
  height: unset;
  margin-right: 5px;
}

.grid-sort-content table tr:first-of-type td {
  padding-top: 3px;
}

.grid-sort-content table td:first-of-type {
  padding-left: 10px;
}

.grid-sort-content table td:last-of-type {
  padding-right: 10px;
}

.grid-sort-content table td {
  cursor: pointer;
}

.grid-sort-content table td svg {
  width: 22px;
  height: 22px;
}

.grid-sort-content table td svg.arrow-down {
  transform: rotateZ(180deg);
}

.grid-sort-content table td svg path {
  stroke: var(--forest-green);
}

.grid-sort-content-checkbox {
  display: flex;
}

.grid-sort-btn-container-up-down {
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  margin-top: 3px;
  margin-bottom: 6px;
}

.grid-sort-btn-container-up-down button {
  width: 30px;
  height: 30px;
  padding-left: 2px;
  padding-right: 2px;
  border-radius: 20px;
  align-self: center;
}

.grid-sort-btn-container-up-down button svg {
  width: 12px;
  height: 12px;
}

.grid-sort-btn-container-up-down button svg.chevron-up {
  transform: rotateZ(180deg);
}