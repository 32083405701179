.textbox input {
  background-color: var(--hazy-blue);
  background-repeat: no-repeat;
  background-position: 7% center;
  border-radius: 100px;
  font-size: 14px;
  height: 33px;
  box-sizing: border-box;
  border: none;
  padding-left: 20px;
  padding-right: 35px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.textbox input::placeholder {
  font-size: 16px;
  font-weight: 500;
  opacity: 1;
  letter-spacing: 0.02em;
  padding-bottom: 12px;
}

.textbox-clear {
  border: 1px solid transparent;
	background-color: transparent;
  outline: 0;
  cursor: pointer;
  position: absolute;
  color: rgb(104, 93, 93);
  font-family: 'Times New Roman', Times, serif;
  font-size: 1.8em;
  font-weight: 600;
  font-style: normal; 
  right: 11px;
  transform: translateY(-50%);
  top: 50%;
}

.textbox-clear:hover {
  color: black;
  font-weight: 900;
}