select.select-answer,
select.select-answer option {
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: var(--off-black);
  opacity: 0.8;
}

select.select-answer option {
  font-weight: 300 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  color: var(--off-black) !important;
}
