.custom-text-area {
  padding-right: 35px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 20px;
  padding-left: 15px;
  box-sizing: border-box;
  background-color: var(--ice-blue);
  border: none;
  width: 100%;
  resize: vertical;
  padding-top: 12px;
  padding-bottom: 12px;
}

.custom-text-area-clear,
.custom-text-area-validation {
	border: 1px solid transparent;
	background-color: transparent;
  outline: 0;
  cursor: pointer;
  position: absolute;
}

.custom-text-area-clear {
  top: 0.25em;
  color: rgb(104, 93, 93);
  font-family: 'Times New Roman', Times, serif;
  font-size: 1.8em;
  font-weight: 600;
  font-style: normal;   
}

.custom-text-area-clear:hover {
  color: black;
  font-weight:900;
}

.custom-text-area-validation {
  top: 19px;
  cursor: default;
  right: 12px;
  transform: scale(2.5); 
  filter: invert(13%) sepia(26%) saturate(7417%) hue-rotate(348deg) brightness(107%) contrast(123%);
}