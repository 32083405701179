.map-answer {
  width: 50%;
  height: auto;
  margin-top: 15px;
  cursor: pointer;
}

svg.map-answer g.map-unselected:hover {
  fill: #FFE88C;
}

svg.map-answer g.map-selected:hover {
  opacity: .8;
}