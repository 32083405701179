.question-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: var(--off-black);
  margin-top: 25px;
}

.question-sub-title {
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 10px;
}
