.check-your-email-header {
  grid-area: header;
}

.check-your-email-header.mobile .navbar {
  padding-left: 10px;
  padding-right: 10px;
}

.check-your-email-main {
  grid-area: main;
  display: flex;
  column-gap: 35px;
  justify-content: center;
  padding: 80px 15px 0 15px;
}

.check-your-email-main.mobile {
  padding: 25px 15px 0 15px; 
}

.check-your-email-main > div {
  flex-basis: 438px;
}

.check-your-email-main > img {
  display: block;
  max-width:682px;
  max-height:358px;
  width: auto;
  height: auto;
}

.check-your-email-link {
  cursor: pointer;
  color: #0000EE;
  text-decoration: underline;
}