.tabbar-container {
  display: flex;
  align-items: center;
  padding-left: 25px;
  padding-right: 26px;
}

.tabbar-divider {
  width: 100%;
}

.tabbar-list {
  padding-left: 0;
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.02em;
  color: var(--dark-gray);
  flex: 1;
}

.tabbar-list-item {
  display: inline-block;
  list-style: none;
  height: 1.3rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  cursor: pointer;
}

.tabbar-list-item:not(:first-child) {
  margin-left: 50px;
}

.tabbar-filter {
  display: table;
}

.tabbar-filter > span {
  display: table-cell;
  vertical-align: middle;
}

.tabbar-divider {
  margin-top: 0;
}