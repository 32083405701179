form.signup-form {
  display: flex;
  flex-direction: column;
  letter-spacing: 0.02em;
  justify-content: space-between;
}

form.signup-form .form-field-message-error {
  font-size: 14px;
}

.signup-form table {
  font-family: Roboto;
  padding-left: 12px; 
  padding-right: 12px; 
  table-layout: fixed;
  border-spacing: 0 10px;
}

.signup-form.hosted-externally table {
  border-spacing: 0 2px;
  padding-left: initial;
  padding-right: initial;
}

.signup-form th {
  font-weight: 600;
  font-size: 18px;
  padding: 0;
  letter-spacing: 0.02em;
  text-align: left;
  color: var(--off-black);
}

.signup-form:not(.hosted-externally) th {
  padding: 0 0 20px 0;
}

.signup-form table tbody tr td .custom-input-container {
  height: 69px;
}

.signup-form.hosted-externally thead th {
  font-weight: 400;
  font-size: 30px;
  letter-spacing: .04em;
  color: white;
  margin-bottom: 20px;
}

.signup-form footer {
  display: flex;
  flex-direction: column;
  padding: 0 32px 32px 32px;
  font-size: 14px;
  gap: 20px;
}

.signup-form.hosted-externally footer {
  padding-bottom: 0;
}

.signup-form footer > :first-child {
  flex: 1;
  text-align: center;
}

.signup-form footer > :last-child {
  margin: 10px 0 10px 0;
  display: flex;
  justify-content: center;
  margin-top: auto;
}

.signup-form .error-message {
  padding-top: 10px;
  padding-bottom: 10px;
  color: darkred;
  text-align: center;
}

.signup-form.hosted-externally .error-message {
  color: red;
}

.signup-form .continue-with-email-btn {
  width: calc(100% - 1px);
  height: 40px;
  box-sizing: border-box;
  border: 1px solid #dadce0;
  border-radius: 4px;
  background-color: white;
  font-family: 'Product Sans', arial, sans-serif;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.signup-form .continue-with-email-btn span {
  flex: 1;
  color: #3c4043;
  font-size: 14px;
  letter-spacing: .25px;
  text-align: center;
  opacity: .84;
}

.signup-form .continue-with-email-img {
  margin-left: 9px;
}

.or-use-email {
  display: flex;
  align-items: center;
  font-size: 16px;;
}

.or-use-email > div {
  padding-top: 10px;
  padding-bottom: 10px;
}

.or-use-email:before,
.or-use-email:after {
  content: "";
  flex-grow: 1;
  height: 1px;
  background-color: var(--divider-color);
  margin-left: 8px;
  margin-right: 8px;
}