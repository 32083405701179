.job-candidates {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.job-candidates-bar {
  height: 51px;
  padding-right: 25px;
}

.job-candidates-bar.navbar {
  padding-left: 0;
}

.job-candidates-bar.navbar .nav-content-end > button {
  margin-left: 15px;
}

.job-candidates .slideout-caption-container {
  margin-top: 10px;
  margin-bottom: 10px;
}

.job-candidates .slideout-content-container {
  padding-left: 0;
  padding-right: 0;
}

.job-candidates-slidout-caption {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.job-candidates-content {
  padding: 24px;
  background-color: #F2F2F2;
  flex: 1;
  min-height: 0;
  display: flex;
}

.job-candidates-content .match-ribbon {
  text-align: center;
  margin-left: -20px;
  margin-top: 7px;
}

.job-candidates-content .match-ribbon img {
  width: 140px;
}

.job-applicant {
  display: flex;
  align-items: center;
}

.job-candidates-driver-item-img-check {
  width: 20px;
  height: 16.87px;
  margin-right: 2px;
}

.job-candidates-driver-item-img-check.driver g {
  fill: var(--medium-gray);
}

.job-candidates-driver-item-img-check.carrier g {
  fill: var(--forest-green);
}