.slideout {
  position: fixed;
  box-sizing: border-box;
  outline: 1px solid lightgray;
  right: -400px;
  width: 400px;
  height: calc(100% - 173px);
  background-color: white;
  box-shadow: 2px 2px 50px rgba(0, 0, 0, 0.05);
  z-index: 1;
  transition: right .4s ease;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
}

.slideout.inline {
  right: unset;
  position: unset;
  transition: margin .4s ease;
  margin-right: -400px;
  height: 100%;
  overflow: hidden;
}

.slideout.inline.show {
  margin-right: 0;
}

.slideout:not(.inline).right.show {
  right: 0;
}

.slideout.left {
  left: -400px;
  transition: left .4s ease;
}

.slideout:not(.inline).left.show {
  left: 0;
}

.slideout-caption-container {
  margin-left: 25px;
  margin-right: 25px;
  height: 45px;
  display: flex;
  flex-direction: row;
}

.slideout-caption-container-custom {
  flex: 1;
}

.slideout-btn {
  background: transparent;
  border: none;
  cursor: pointer;
}

.slideout-btn svg {
  width: 18px;
  height: 18px;
  opacity: 1;
}

.slideout-btn:hover {
  opacity: .7;
}

.slideout-content-container {
  padding-left: 32px; 
  padding-right: 32px; 
  overflow-y: auto;
  flex: 1;
}