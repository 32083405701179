.match-percent-dot {
  height: 10px;
  width: 10px;
  background-color: var(--sunset-orange);
  border-radius: 50%;
  display: inline-block;
}

.match-percent-text {
  margin-left: 8px;
}