.jobs-view-bar {
  height: 51px;
}

.jobs-view-bar.navbar {
  padding-left: 0;
}

.jobs-view-caption {
  display: flex;
  align-items: center;
}

.jobs-view-caption-status {
  border-radius: 50%;
  width: 14px;
  height: 14px;
}

.jobs-view-caption-status.caption-draft {
  background-color: var(--colorado-blue);
}

.jobs-view-caption-status.caption-published {
  background-color: var(--forest-green);
}

.jobs-view-caption-status.caption-archived {
  background-color: var(--sunset-orange);
}

.jobs-view-caption > span {
  margin-left: 8px; 
}

.jobs-view-caption-count {
  width: 24px;
  height: 20px;
  margin-left: 9px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  outline: 1px solid var(--off-black);
}

.jobs-view-caption-count > span {
  margin: 0 auto;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: black;
}

.tabbar-list-item.tab-active {
  border-bottom: 3px solid var(--forest-green);
}

.tabbar-list-item.tab-active-draft {
  border-bottom: 3px solid var(--colorado-blue);
}

.tabbar-list-item.tab-active-published {
  border-bottom: 3px solid var(--forest-green);
}

.tabbar-list-item.tab-active-archived {
  border-bottom: 3px solid var(--sunset-orange);
}
.tabbar-list-item:not([class*="tab-active"]):hover {
  opacity: .6;
}

.jobs-view-filter {
  float: left;
  width: 35px;
  height: 35px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.jobs-view-filter:first-of-type {
  margin-right: 15px;
}

.jobs-view-filter svg {
  margin: 0 auto;
}

.jobs-view-filter.filter-active {
  background-color: var(--hazy-blue);
}

.jobs-view-main {
  display: grid;
  grid-template-rows: 50px 1fr;
  height: 100%;
  overflow: hidden;
}

.jobs-view-main > nav {
  grid-area: unset;
  align-items: center;
  height: 50px;
  min-height: 50px;
  padding-left: 25px;
  padding-right: 25px;
}

.jobs-view-content {
  padding: 24px;
  background-color: #F2F2F2;
  min-height: 0;
  display: flex;
}

.jobs-view-content.tile-view {
  align-items: flex-start;
  overflow: auto;
}

.jobs-applicant {
  display: flex;
  align-items: center;
}

.jobs-applicant div {
  padding: 3px 7px;
  display: flex;
  align-items: center;
}

.jobs-applicant div:not(:first-of-type) {
  margin-left: 9px;
}

.jobs-applicant div:first-of-type {
  border-radius: 4px;
  background-color: var(--key-lime);
}
