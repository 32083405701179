.modal-announcement {
  width: 384px !important;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-top: 50px;
  padding-bottom: 25px;
}

.modal-announcement svg {
  align-self: center;
}

.modal-announcement span {
  color: var(--off-black);
  margin-top: 25px;
  font-weight: 300;
  font-size: 12px;
  align-self: center;
}

.modal-announcement button {
  margin: 30px 25px 0 30px;
  height: 41px;
  font-size: 15px !important;
  line-height: 18px;
}
