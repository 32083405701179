.edit-carrier-header {
  grid-area: header;
}

.edit-carrier-header .navbar {
  background: var(--off-black);
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.08);
}

.edit-carrier-main {
  grid-area: main;
  padding-top: 35px;
  padding-left: 120px;
  padding-right: 120px;
  overflow-y: auto;
}

.edit-carrier-main-image {
  float: left;
  margin-right: 30px;
}

.edit-carrier-main-text {
  font-style: normal;
  font-weight: 300;
  font-size: 38px;
  line-height: 150%;
  letter-spacing: 0.04em;
  color: var(--off-black);
}

.edit-carrier-main-subtext {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: var(--off-black); 
}

.edit-carrier-main-divider {
  margin-top: 30px;
}

.edit-carrier-main-header {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: var(--off-black);
  margin-top: 30px;
  margin-bottom: 0;
}

.edit-carrier-main-subheader {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: var(--off-black);
  padding-top: 0;
}

.edit-carrier-main-form {
  margin-top: 32px;
}

.edit-carrier-main-form td {
  padding-bottom: 20px;
}

.edit-carrier-main-form td > span:nth-of-type(1) {
  display: inline-block;
  color: var(--off-black);
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  padding-bottom: 15px;
}

.edit-carrier-main-form td > .upload {
  padding-bottom: 0;
}

.edit-carrier-main-form td > button {
  margin-bottom: 30px;
  margin-top: 15px;
}

.edit-carrier-main-form .error-message {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}

.edit-carrier-main footer {
  text-align: right;
  padding-bottom: 30px;
}
