.grid-container {
  overflow-y: auto;
  flex: 1;
}

.grid {
  width: 100%;
  background-color: white;
  border-collapse: separate;
  border-spacing: 0;
  color: var(--off-black);
}

.grid thead {
  top: 0;
  position: sticky;
  box-shadow: inset 0 1px var(--hazy-blue);
}

.grid th {
  height: 52px;
  text-align: left;
  background-color: white;
  box-shadow: inset 0 -1px var(--hazy-blue)
}

.grid tbody tr {
  height: 45px;
  cursor: pointer;
}

.grid tfoot {
  height: 12px;
}

.grid th,
.grid td {
  border-left: 1px solid var(--hazy-blue); 
  border-right: 1px solid var(--hazy-blue); 
}

.grid td {
  border-bottom: 1px solid var(--hazy-blue); 
}

.grid tr th,
.grid tr td {
  padding-left: 20px;
}

.grid tbody tr:first-child td {
  border-top: 1px solid white !important;
}

.grid tbody tr.row-select {
  background-color: rgba(70, 149, 111, .2);
}

.grid tr th svg {
  height: 14px;
  margin-left: 7px;
  vertical-align: middle;
  margin-top: -2px;
}

.grid tr th.sortable {
  cursor: pointer;
}

.grid-column-sort-arrow-down {
  transform: rotate(0deg);
  transition: transform .1s linear;
}

.grid-column-sort-arrow-up {
  transform: rotateZ(180deg);
  transition: transform .1s linear;
}

.grid tbody tr:hover:not(.row-select) {
  background-color: var(--hover);
}