.custom-select {
  font-size: 20px;
  font-family: 'Roboto';
  font-weight: 300;
  height: 48px;
  padding-left: 12px;
  box-sizing: border-box;
  background-color: var(--ice-blue);
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; 
  appearance: none;
  border: none;
}

/* For IE */
.custom-select::-ms-expand {
  display: none;
}

.custom-select-dropdown {
  pointer-events: none;
}

.custom-select-clear,
.custom-select-dropdown,
.custom-select-validation {
	border: 1px solid transparent;
	background-color: transparent;
  outline: 0;
  cursor: pointer;
  position: absolute;
}

.custom-select-clear {
  top: 0.22em;
  color: rgb(104, 93, 93);
  font-family: 'Times New Roman', Times, serif;
  font-size: 1.8em;
  font-weight: 600;
  font-style: normal;   
}

.custom-select-clear:hover {
  color: black;
  font-weight:900;
}

.custom-select option {
  color: var(--off-black) !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.04em;
}

select.custom-select:not(option):invalid {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--medium-gray);
  opacity: 0.8;
  letter-spacing: 0.04em;
}

.custom-select-dropdown,
.custom-select-validation {
  top: 18px;
  filter: invert(41%) sepia(51%) saturate(0%) hue-rotate(29deg) brightness(100%) contrast(92%);
  transform: scale(2.5); 
}

.custom-select-dropdown {
  top: 19px;
  transform: scale(2);
}

.custom-select-dropdown:hover {
  filter: invert(0%) sepia(100%) saturate(29%) hue-rotate(128deg) brightness(105%) contrast(107%);
}

.custom-select-validation {
  cursor: default;
  right: 12px;
  filter: invert(13%) sepia(26%) saturate(7417%) hue-rotate(348deg) brightness(107%) contrast(123%);
}
