.modal {
  --spacing: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  backdrop-filter: blur(20px);
  z-index: 2;
}

.modal-inner {
  position: relative;
  min-width: 100px;
  max-width: 100%;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 20px 32px -8px;
}

.modal-back-btn,
.modal-close-btn {
  position: absolute;
  top: var(--spacing);
  right: var(--spacing);
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;
}

.modal-back-btn {
  top: 29.9px;
  left: 28.9px;
  right: initial;
}

.modal-back-btn svg {
  width: 13px;
  height: 13px;
  opacity: .5;
}

.modal-close-btn svg {
  width: var(--spacing);
  height: var(--spacing);
  fill: currentColor;
  opacity: .5;
}

.modal-back-btn svg path {
  stroke: currentColor;
  fill: none;
}

.modal-back-btn:hover svg,
.modal-close-btn:hover svg {
  opacity: 1;
}

