.loader {
   z-index: 100;
   height: 0;
}

.loader_cogs {
  z-index: 1;
  width: 80px;
  height: 80px;
  top: -80px !important;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  animation: fadeIn ease 2s;
}

.loader_cogs__top {
  position: relative;
  width: 80px;
  height: 80px;
  transform-origin: 40px 40px;
  -webkit-animation: rotate 10s infinite linear;
  animation: rotate 10s infinite linear;
}

.loader_cogs__top div:nth-of-type(1) {
  transform: rotate(30deg);
}

.loader_cogs__top div:nth-of-type(2) {
  transform: rotate(60deg);
}

.loader_cogs__top div:nth-of-type(3) {
  transform: rotate(90deg);
}

.loader_cogs__top div.top_part {
  width: 80px;
  border-radius: 8px;
  position: absolute;
  height: 80px;
  background: var(--app-green);
}

.loader_cogs__top div.top_hole {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: white;
  position: absolute;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.loader_cogs__left {
  position: relative;
  width: 64px;
  transform: rotate(16deg);
  top: 22px;
  transform-origin: 32px 32px;
  animation: rotate_left 10s 0.1s infinite reverse linear;
  left: -21px;
  height: 64px;
}

.loader_cogs__left div:nth-of-type(1) {
  transform: rotate(30deg);
}

.loader_cogs__left div:nth-of-type(2) {
  transform: rotate(60deg);
}

.loader_cogs__left div:nth-of-type(3) {
  transform: rotate(90deg);
}

.loader_cogs__left div.left_part {
  width: 64px;
  border-radius: 6px;
  position: absolute;
  height: 64px;
  background: darkgray;
}

.loader_cogs__left div.left_hole {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background: white;
  position: absolute;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.loader_cogs__bottom {
  position: relative;
  width: 48px;
  top: -52px;
  transform-origin: 24px 24px;
  animation: rotate_left 10s 0.4s infinite linear;
  transform: rotate(4deg);
  left: 60px;
  height: 48px;
}

.loader_cogs__bottom div:nth-of-type(1) {
  transform: rotate(30deg);
}

.loader_cogs__bottom div:nth-of-type(2) {
  transform: rotate(60deg);
}

.loader_cogs__bottom div:nth-of-type(3) {
  transform: rotate(90deg);
}

.loader_cogs__bottom div.bottom_part {
  width: 48px;
  border-radius: 4px;
  position: absolute;
  height: 48px;
  background: #ffcd66;
}

.loader_cogs__bottom div.bottom_hole {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: white;
  position: absolute;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

/* Animations */
@-webkit-keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate_left {
  from {
    transform: rotate(16deg);
  }

  to {
    transform: rotate(376deg);
  }
}

@keyframes rotate_left {
  from {
    transform: rotate(16deg);
  }

  to {
    transform: rotate(376deg);
  }
}

@-webkit-keyframes rotate_right {
  from {
    transform: rotate(4deg);
  }

  to {
    transform: rotate(364deg);
  }
}

@keyframes rotate_right {
  from {
    transform: rotate(4deg);
  }

  to {
    transform: rotate(364deg);
  }
}


@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}
