input[type="date"],
input[type="email"],
input[type="number"],
input[type="text"],
input[type="password"] {
  font-size: 20px;
  height: 48px;
  padding-left: 15px;
  box-sizing: border-box;
  background-color: var(--ice-blue);
  border: none;
  width: 100%;
}

input[type="number"].monetary {
  padding-left: 27px;
}

.custom-input-container,
.custom-input-container > div {
  position: relative;
}

.custom-input {
  padding-right: 35px; /* Accommodates clear, validation, and pwd glypths */
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.custom-input-monetary {
  border: 1px solid transparent;
	background-color: transparent;
  outline: 0;
  position: absolute;
  color: rgb(104, 93, 93);
  font-style: normal; 
  left: 15px;
  transform: translateY(-50%);
  top: 50%;
}

.custom-input-clear,
.custom-input-pwd,
.custom-input-search,
.custom-input-validation,
.custom-input-info {
	border: 1px solid transparent;
	background-color: transparent;
  outline: 0;
  cursor: pointer;
  transform: translateY(-50%);
  top: 50%;
  position: absolute;
}

.custom-input-clear {
  color: rgb(104, 93, 93);
  font-family: 'Times New Roman', Times, serif;
  font-size: 1.8em;
  font-weight: 600;
  font-style: normal;   
}

.custom-input-clear:hover {
  color: black;
  font-weight: 900;
}

.custom-input-pwd,
.custom-input-validation,
.custom-input-info {
  filter: invert(41%) sepia(51%) saturate(0%) hue-rotate(29deg) brightness(100%) contrast(92%);
  cursor: default;
  right: 6px;
}

.custom-input-pwd {
  cursor: pointer;
}

.custom-input-info {
  filter: unset;
}

.custom-input-search {
  filter: invert(20%) sepia(51%) saturate(29%) hue-rotate(29deg) brightness(105%) contrast(107%);
}

.custom-input-search:hover {
  filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(29deg) brightness(100%) contrast(100%);
}

.custom-input-pwd:hover {
  filter: invert(0%) sepia(100%) saturate(29%) hue-rotate(128deg) brightness(105%) contrast(107%);
}

.custom-input-validation {
  filter: invert(13%) sepia(26%) saturate(7417%) hue-rotate(348deg) brightness(107%) contrast(123%);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}

/* Edge */
::-ms-reveal {
  display: none; /* Hide the show pwd glyph */
}