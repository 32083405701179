div.divider-light,
div.divider-dark {
  min-height: .8px;
  max-height: .8px;
  padding: 0 !important;
  border: 0;
}

div.divider-light {
  border-color: var(--hazy-blue);
  background-color: var(--hazy-blue);
}

div.divider-dark {
  border-color: var(--medium-gray);
  background-color: var(--medium-gray);
}