.not-found-header {
  grid-area: header;
}

.not-found-main {
  grid-area: main;
  display: flex;
  column-gap: 200px;
  justify-content: center;
  padding: 80px 10px 0 10px;
}

.not-found-main > div {
  padding-top: 50px;
  text-align: left;
}

.not-found-main > svg {
  padding-top: 0;
}