.accordion {
  flex: 1;
  padding-left: 2px;
  padding-right: 12px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

div.accordion-section {
  padding: 18px;
  outline: none;
  display: flex;
  line-height: 14px;
  border-radius: 4px;
  font-style: normal;
  font-size: 12px;
  cursor: pointer;
}

.accordion-section > div {
  flex: 1;
}

.accordion-section > div > span {
  font-weight: 900;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.1px;
  color: #FFFFFF;
  margin-left: 15px;
  vertical-align: middle;
}

.accordion-section.section-selected {
  background-color: #FFFFFF;
  --svgaccordioncolor:var(--off-black);
  --svgaccordionbackgroundcolor: #FFFFFF;
}

.accordion-section.section-selected > div > span {
  color: var(--off-black);
}

.accordion-section:hover:not(.section-selected) {
  background-color: #4d4b4b;
  --svgaccordionbackgroundcolor: #4d4b4b;
}

.accordion-content {
  margin-top: 2px;
  outline: none;
  max-height: 1000px;
  padding-left: 12px;
  padding-right: 2px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  transition: max-height .4s ease-in;
}

.accordion-content.content-closed {
  max-height: 0;
  transition: max-height .4s ease-out;
}

.accordion-content > div {
  display: flex;
  color: #FFFFFF;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 4px;
  cursor: pointer;
}

.accordion-content > div.section-selected {
  color: var(--off-black);
  background-color: #FFFFFF;
}

.accordion-content > div:hover:not(.section-selected) {
  background-color: #4d4b4b;
}

.accordion-content .accordion-dot {
  height: 10px;
  width: 10px;
  background-color: var(--sunset-orange);
  border-radius: 50%;
  display: inline-block;
  margin-left: 7px;
  margin-top: 2px;
}

.accordion-content > div > span:nth-of-type(2) {
  font-size: 12px;
  font-style: normal;
  margin-left: 8px;
  margin-top: .1px;
  flex: 1;
}

.accordion-content > div:last-child > span:first-of-type {
  background-color: var(--colorado-blue)
}

.accordion-button {
  padding: 0;
  background: transparent;
  border: none;
  opacity: 0.5;
  cursor: pointer;
  outline: none;
}

.accordion-button:hover {
  opacity: 1;
}

.accordion-button svg {
  fill: white;
}

.accordion-section.section-selected .accordion-button svg {
  fill: var(--off-black);
}

.accordion-button-caret-down {
  transform: rotate(0deg);
  transition: transform .38s linear;
}

.accordion-button-caret-up {
  transform: rotateZ(180deg);
  transition: transform .2s linear;
}

.show-scrollbar {
  scrollbar-width: thin;
}

.show-scrollbar::-webkit-scrollbar {
  background-color: rgb(79, 87, 97);
  border-radius: 100px;
  width: 5px;
}

.show-scrollbar::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 100px;
}

.hide-scrollbar {
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  background-color: var(--off-black);
  border-radius: 100px;
  width: 0;
}

.hide-scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--off-black);
  border-radius: 100px;
}
