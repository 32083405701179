.range-answer-values {
  margin-top: 25px;
  display: flex;
}

.range-answer-values > div {
  display: grid;
}

.range-answer-values > div:first-of-type {
  margin-right: 7px;
}

.range-answer-values > div:last-of-type {
  margin-left: 7px;
}

.range-answer-values > div > input {
  flex: 1;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: var(--off-black);
  opacity: 0.8;
}

.range-answer-values > div > span {
  margin-top: 2px;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  color: var(--off-black);
  opacity: 0.8;
}
