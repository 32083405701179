.dropdown-menu {
  position: relative;
  display: inline-block;
}

.dropdown-menu-component {
  cursor: pointer;
}

.dropdown-menu-content {
  cursor: pointer;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  display: none;
  position: absolute;
  float: right;
  right: 0;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 2;
  overflow: hidden;
  background-color: white;
}

.dropdown-menu-content.align-left-edge {
  right: unset;
  left: 0;
}

.dropdown-menu-content {
  white-space: nowrap;
}

.dropdown-menu-content .dropdown-menu-item:first-child {
  padding-top: 12px;  
}

.dropdown-menu-content .dropdown-menu-item:last-child { 
  padding-bottom: 12px;  
}

.dropdown-menu-item {
  display: flex;
  flex-direction: row;
  padding-top: 6px;
  padding-bottom: 6px;  
  padding-right: 20px;
}

.dropdown-menu-item-check > span {
  display: none;
}
.dropdown-menu-item.selected .dropdown-menu-item-check > span {
  display: block;
}

.dropdown-menu-item:hover {
  background-color: #ddd;
}

.dropdown-menu-content.show {
  display: block;
}

.dropdown-menu-item-check {
  width: 35px;
  padding-right: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: right;
  color: var(--off-black);
  font-size: 18px;
}

.dropdown-menu-item-text {
  white-space: nowrap;
  display: flex;
  align-items: center;
  flex: 1;
}