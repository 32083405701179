section.modal-login {
  width: 384px !important;
  display: flex;
  align-items: stretch;
}

.modal-login > form {
  flex: 1;
  flex-direction: column;
  letter-spacing: 0.02em;
  justify-content: space-between;
  display: flex;
}

.modal-login table {
  font-family: Roboto;
  margin-top: 32px;
  padding-left: 32px; 
  padding-right: 32px; 
  table-layout: fixed;
  border-spacing: 0 10px;
}

.modal-login .truckerfy-logo span {
  font-size: 32px;
}

.modal-login .truckerfy-logo svg {
  height: 50px;
  margin-top: -1px;
  margin-right: 8px;
}

.modal-login th {
  padding: 0 0 20px 0;
  text-align: center;
}

.modal-login table tbody tr td .custom-input-container {
  height: 69px;
}

.modal-login input[type="text"],
.modal-login input[type="email"],
.modal-login input[type="password"] {
  font-size: 16px;
}

.modal-login input[type="checkbox"] {
  margin: 0 10px 0 15px !important;
  width: 18px;
  height: 18px;
  border: 1px solid var(--off-black);
  box-sizing: border-box;
  border-radius: 2px;
  vertical-align: middle;
}

.modal-login label {
  display: inline-block;
  color: var(--off-black);
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: 0.02em;
}

.modal-login label span {
  vertical-align: bottom;
}

.modal-login .login-link {
  color: var(--app-green);
  text-decoration: none;
  font-size: 14px;
  letter-spacing: .02em;
  margin: 0 10px 0 15px !important;
  cursor: pointer;
}

.modal-login footer {
  padding: 0 32px 32px 32px;
}

.modal-login button {
  width: 100%;
  height: 41px;
  font-size: 15px !important;
  line-height: 18px;
  margin: 10px 0px 10px 0px;
}

.modal-login .success-message {
  padding-top: 10px;
  padding-bottom: 10px;
  color: var(--app-green);
  text-align: center;
}

.modal-login .error-message {
  padding-top: 10px;
  padding-bottom: 10px;
  color: darkred;
  text-align: center;
}
