.oops-header {
  grid-area: header;
}

.oops-main {
  grid-area: main;
  display: flex;
  column-gap: 200px;
  justify-content: center;
  padding: 80px 10px 0 10px;
}

.oops-main > div {
  padding-top: 50px;
  text-align: left;
}

.oops-main > svg {
  padding-top: 85px;
}