.dashbar {
  box-shadow: none !important;
  padding-left: 25px;
  padding-right: 25px;
  margin-top: 15px;
}

.dashbar .job-preElement,
.dashbar .area-preElement {
  margin-right: 12px;
  width: 35px;
  height: 35px;
}

.dashbar .area-preElement {
  border-radius: 4px;
  display: flex;
  align-items: center;
  background-color: rgba(70, 149, 111, .2);
  --svgaccordioncolor: var(--off-black);
  --svgaccordionbackgroundcolor: #DCEAE2;
}

.dashbar .area-preElement svg {
  margin: 0 auto;
}

.navbar.dashbar .dropdown-menu-content.show {
  z-index: 3;
}

.dashbar-user-avatar-name {
  display: flex;
  align-items: center;
}

.dashbar-user-avatar-name-caret {
  transform: rotate(0deg);
  transition: transform .1s linear;
}

.dashbar-user-avatar-name-caret:hover {
  opacity: 1 !important;
}

.dropdown-menu-component.show-menu.dashbar-user-avatar-name svg {
  transform: rotateZ(180deg);
  transition: transform .1s linear;
}
