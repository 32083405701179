.navbar {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  height: 56px;
  grid-area: header;
  padding-left: 50px;
  padding-right: 50px;
}

.nav-logo {
  margin-top: -2px;
  font-size: 24px;
  margin-right: auto;
  display: flex;
  align-items: center;
}

.nav-content-container {
  display: flex;
  flex: 1;
  row-gap: 15px;
  padding-left: 25px;
}

.nav-content-start {
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1;
}

.nav-content-end {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.nav-end-responsive-stack {
  margin-right: 10px;
}

.nav-end-responsive-stack .dropdown-menu > svg {
  width: 3em;
  height: 3em;
}