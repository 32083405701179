.dashboard-main {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.dashboard-main-header {
  height: 56px;
}

.dashboard-main-section {
  flex: 1;
  padding-top: 27px;
  overflow: hidden;
}