section.modal-edit-confirm-text {
  width: 512px !important;
  display: flex;
  align-items: stretch;
  padding-bottom: 16px;
}

.modal-edit-confirm-text-header {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.modal-edit-confirm-text-header > svg:first-of-type,
.modal-edit-confirm-text-header > svg:last-of-type  {
  align-self: center;
  width: 22px;
  height: 22px;
  color: rgb(248, 186, 71);
  margin-right: 5px;
  margin-top: 29px;
}

.modal-edit-confirm-text-header > svg:last-of-type  {
  margin-left: 5px;
  transform: rotate(45deg);
}

.modal-edit-confirm-text-header > svg:nth-of-type(2)  {
  width: 90px;
  height: 90px;
}

.modal-edit-confirm-text > form {
  flex: 1;
  flex-direction: column;
  letter-spacing: 0.02em;
  justify-content: space-between;
  display: flex;
}

.modal-edit-confirm-text textarea {
  font-size: 18px;
  letter-spacing: .02em;
}

.modal-edit-confirm-text table {
  padding-left: 32px; 
  padding-right: 32px; 
  table-layout: fixed;
  border-spacing: 0 10px;
}

.modal-edit-confirm-text-footer {
  margin-top: 10px;
  display: flex;
}

.modal-edit-confirm-text-footer > button {
  flex: 1;
}