@import url('//fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;600&display=swap');
@import url('//fonts.googleapis.com/css2?family=Sen:wght@700&display=swap');
@import url('https://fonts.cdnfonts.com/css/google-sans');
@import url('//fonts.cdnfonts.com/css/sf-pro-display');

:root {
  --app-green: #2CDDC0;
  --app-green-bg: #2CDDC035;

  --button-grn-text: white;
  --button-grn-background: var(--app-green);
  --button-grn-border: transparent;
  --button-grn-hover: #467069;
  --button-grn-focus: #3e5753;
  --button-grn-disabled-background: #c4c4c4;
  --button-grn-disabled-text: white;
  --button-grn-ripple: rgba(255, 255, 255, 0.7);

  --button-wht-text: #57585B;
  --button-wht-background: #ffffff;
  --button-wht-border: #57585B;
  --button-wht-hover: #f8f7fa;
  --button-wht-focus: #f0f0f0;
  --button-wht-disabled-background: gray;
  --button-wht-disabled-text: #f3f2f5;
  --button-wht-ripple: rgba(87, 88, 91, 0.7);

  --hover: rgba(235, 237, 243, .4);
  --hoverSolid: rgb(235, 237, 243);

  --svgaccordioncolor: white;
  --svgaccordionbackgroundcolor: #57585B;

  --off-black: #0A0C0B;
  --light-gray: rgb(190, 189, 189);
  --lm-gray: #979797;
  --medium-gray: #898A8D;
  --dark-gray: #57585B;
  --key-lime: rgba(70, 149, 111, 0.2);
  --hazy-blue: #ECF0F3;

  --green-tea: #F0FAF5;
  --forest-green: var(--app-green);
  --forest-green-bg: var(--app-green-bg);

  --ice-blue: #F9FBFD;
  --colorado-blue: #00A3FF;
  --colorado-blue-bg: #00A3FF35;
 
  --sunset-orange: #FFBD00;
  --sunset-orange-bg: #FFBD0035;
  --crimson-red: #E3170A;
  --crimson-red-bg: #E3170A35;

  --divider-color: rgb(192, 202, 213);
  --placeholder-color: #898A8D;
  --placeholder-opacity: .8;
}

html, 
body, 
#root,
input,
textarea {
  height: 100%;
  overflow: hidden;  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
