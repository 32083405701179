.job-post-header > div:first-of-type {
  margin-left: -32px;
  margin-right: -32px;
}

.job-post-header.no-auth > div:first-of-type {
  margin-left: -20px;
  margin-right: -20px;
}

.job-post-header-carrier-banner {
  max-width: 100%;
  outline: 1px solid rgba(211, 211, 211, .5)
}

.job-post-header-banner-match {
  display: flex;
}

.job-post-header-carrier-logo {
  margin-top: -51px;
  flex: 1;
  outline: 1px solid rgba(211, 211, 211, .5)
}

.job-post-header-match-container {
  margin-top: -25px;
  margin-left: auto;
  margin-right: -12px;
}

.job-post-header-logo-container {
  height: 145px;
  float: left;
  margin-right: 15px;
}

.job-post-header-logo-container.compact {
  height: 65px;
}

.job-post-header-btn-svg-container {
  margin-top: 18px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.job-post-header-btn-svg-container button {
  z-index: -1;
  /* border-radius: 0; */
  color: var(--off-black);
  flex: 1;
}

.job-post-header-btn-svg-container svg {
  width: 30px;
  height: 30px;
  --svgaccordioncolor: var(--off-black);
  --svgaccordionbackgroundcolor: white;
}

.job-post-header button {
  padding: 6px 12px 6px;
}

.job-post-header-logo {
  font-family: Arial, Helvetica, sans-serif;
  font-style: bold;
  font-size: 18px;
  font-weight: 900;
  color: #D2042D;
  letter-spacing: -.1em;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width:44px;
  height:44px; 
  outline:none;
  border: none;
  background-color: var(--hazy-blue);
}

.job-post-header-text {
  margin-top: 14px;
  margin-bottom: 4px;
}

.job-post-header-text p {
  margin-top: 0;
  margin-bottom: 4px;
}

.job-post-header-text p:nth-of-type(2) {
  margin-top: 14px;
}

.job-post-grid {
  display: grid; 
  grid-template-columns: 30px 1fr 30px 1fr;
  margin-top: 10px;
  margin-bottom: 10px;
  align-items: center;
  row-gap: 4px;
}

.job-post-grid svg {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  justify-self: center;
}

.job-post-answer {
  white-space: pre-wrap;
  padding-bottom: 15px;
}

svg.read-only-map-answer.job-post-map-answer {
  width: 100%;
  height: auto;
  margin-top: 10px;
}

div.divider-light.job-post-divider {
  min-height: 2px;
  max-height: 2px;
  border-color: var(--app-green);
  background-color: var(--app-green);
}