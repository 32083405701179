.job-overview {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.job-bar .job-bar-btn-container {
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.job-bar .job-bar-btn-container:hover {
  background-color: var(--hover);
}

.job-bar .job-bar-btn-container > span {
  font-size: 12px;
}

.job-overview-content {
  padding-top: 24px;
  padding-right: 24px;
  padding-bottom: 5px;
  padding-left: 24px;
  background-color: #F2F2F2;
  flex: 1;
  overflow: auto;
}

.job-overview-content-btn-container button {
  width: 151px;
}

.overview-views-cell {
  padding-bottom: 4px;
  padding-right: 12px;
}

.job-overview-content-chart-container {
  padding-top: 4px;
  display: flex;
}

.job-overview-views-container {
  display: flex;
  flex-direction: column;
  gap: 13px;
}

.job-overview-view-container {
  display: flex;
  gap: 13px; 
}

.job-overview-view,
.job-overview-widget {
  background-color: white;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, .08);
  border-radius: 4px;
}

.job-overview-widget:hover:not(.no-hover) {
  outline: var(--medium-gray) 2px solid;
}

.job-overview-content-widget-container {
  display: flex;
  flex-direction: column;
  gap: 13px;
}

.job-overview-view {
  width: 30vw;
  height: 23vh;
  min-width: 312px;
  min-height: 242px;
  display: flex;
  flex-direction: column;
}

.job-overview-view-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
  margin-left: 30px;
}

.job-overview-view-header > svg {
  margin-right: 10px;
  height: 30px;
  width: 30px;
}

.job-overview-view .job-overview-view-chart {
  width: 100%;
  height: calc(100% - 60px);
}

.job-overview-view-chart .no-data {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.job-overview-view-chart .no-data > span {
  margin-top: -53px;
}

.job-overview-widget {
  width: 203px;
  padding: 14px;
}

.job-overview-widget-inner {
  margin-top: 3px;
  margin-left: 2px;
  margin-bottom: 2px;
}

.job-overview-widget-img-container {
  float: left;
  margin-right: 16px;
}

.job-overview-widget-img {
  border-radius: 50%;
  width:39px;
  height:39px;
  opacity: .4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.job-overview-widget-img.glyph {
  background-color: var(--dark-gray);
}

.job-overview-widget:hover .glyph.hoverable {
  background-color: var(--forest-green); 
}

.job-overview-widget-img svg {
  fill: white;
}

.job-overview-widget-img svg.move-up {
  margin-top: -4px;
}

.job-overview-widget-img svg.fix-color {
  --svgaccordionbackgroundcolor: var(--dark-gray);
}

.job-overview-widget p {
  margin-top: 0;
  margin-bottom: 3px;
}

.job-overview-widget p:last-of-type {
  margin-top: 3px;
  margin-bottom: 0;
}

.job-overview-widget > svg:first-of-type {
  float: right;
  top: 0;
  left: 20px;
}

.job-overview-widget > svg:first-of-type path {
  stroke: var(--medium-gray);
}

.job-overview-widget:hover > svg:first-of-type path {
  stroke: var(--forest-green); 
}
