.driverApp-main {
  font-family: Roboto;
  margin-left: 20px;
  margin-right: 20px;
}
.driverApp-main h1 {
  font-size: 24px;
  color: var(--off-black);
  line-height: 120%;
  letter-spacing: 0.04em;
}

.driverApp-main p {
  padding-bottom: 15px;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: var(--off-black);
}
