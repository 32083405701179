.candidates-view-header {
  background-color: white;
  vertical-align: middle;
}

.candidates-view-header-buffer {
  height: 39px;
}

.candidates-view-main {
  padding: 24px;
  background-color: #F2F2F2;
  flex: 1;
  min-height: 0;
  display: flex;
}

.candidates-view-main .match-ribbon {
  text-align: center;
  margin-left: -20px;
  margin-top: 7px;
}

.candidates-view-main .match-ribbon img {
  width: 140px;
}

.candidates-bar {
  padding-right: 25px;
  height: 51px;
}

.candidates-bar.navbar {
  padding-left: 0;
}

.candidates-bar.navbar .nav-content-end > button {
  margin-left: 15px;
}

.candidates-bar-sort-filter-container {
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.candidates-bar-sort-filter-container:hover {
  background-color: var(--hover);
}

.candidates-bar-sort-filter-container > svg {
  width: 16px;
  height: 16px;
}

.candidates-bar-filter-container:hover {
  background-color: var(--hover);
}

.candidates-bar-filter-container > span {
  font-size: 12px;
}

.dropdown-container-component.show-filter.candidates-bar-filter-container {
  background-color: var(--hazy-blue) !important;
}

.candidates-bar-filter-content {
  cursor: default;
  width: 502px;
  height: 259px;
  padding-top: 10px;
  padding-bottom: 40px;
  padding-left: 25px;
  padding-right: 25px;
}

.candidates-bar-filter-content-match-container {
  display: flex;
  gap: 2px;
}

.candidates-bar-filter-content-match-container button.custom-btn-wht {
  border-radius: 6px;
  padding: 5px;
  text-transform: none;
  color: var(--light-gray);
  border: 2px solid var(--hazy-blue);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: .01em;
  line-height: unset;
}

.candidates-bar-filter-content-match-container button {
  margin-left: 0;
}

.candidates-bar-filter-content-match-container button.match-great.state-btn-selected {
  border: 2px solid var(--forest-green);
  color: var(--forest-green);
}

.candidates-bar-filter-content-match-container button.match-good.state-btn-selected {
  border: 2px solid var(--sunset-orange);
  color: var(--sunset-orange);
}

.candidates-bar-filter-content-match-container button.match-weak.state-btn-selected {
  border: 2px solid var(--crimson-red);
  color: var(--crimson-red);
}

.candidates-bar-filter-content-match-container button.state-btn-selected {
  background-color: unset !important;
}

.candidates-bar-filter-content-button-container {
  margin-top: 25px;
  display: flex;
  flex-direction: row-reverse;
  gap: 25px;
}

.candidates-view-main .slideout-caption-container {
  margin-top: 10px;
  margin-bottom: 10px;
}

.candidates-view-main .slideout-content-container {
  padding-left: 0;
  padding-right: 0;
}

.candidates-view-main-driver-item-img-check {
  width: 20px;
  height: 16.87px;
  margin-right: 2px;
}

.candidates-view-main-driver-item-img-check.driver g {
  fill: var(--medium-gray);
}

.candidates-view-main-driver-item-img-check.carrier g {
  fill: var(--forest-green);
}