.appStoreButtons {
  text-align: center;
  margin-top: 10px;
}

.appStoreButtons > svg {
  height: 55.8px;
  width: unset;
  padding-bottom: 1px;
  margin: 0 10px 0 10px; 
  cursor: pointer;
}

.appStoreButtons > img {
  width: 190px;
  margin: 0 10px 0 10px; 
  cursor: pointer;
}