.sidebar {
  background-color: var(--dark-gray);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-top: 10px;
  padding-right: 0;
  padding-bottom: 28px;
  padding-left: 10px;
  width: 220px;
  min-width: 220px;
  height: 100%;
}

.sidebar-carrier-logo-name {
  display: flex;
  align-items: center;
  padding-right: 7px;
}

.sidebar-carrier-logo-name > div:first-of-type {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 10px;
}

.sidebar-carrier-logo-name-caret {
  transform: rotate(0deg);
  transition: transform .1s linear;
}

.sidebar-carrier-logo-name-caret:hover {
  opacity: 1 !important;
}

.dropdown-menu-component.show-menu.sidebar-carrier-logo-name svg {
  transform: rotateZ(180deg);
  transition: transform .1s linear;
}

.sidebar-logo {
  margin-top: 5px;
  padding-right: 10px;
  text-align: center;
}

.sidebar-divider {
  margin-top: 15px;
  margin-bottom: 14px;
  margin-right: 10px;
}

.sidebar-tip {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  font-style: normal;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  margin-top: 16px;
  margin-right: 24px;
  margin-left: 14px;  
  padding-top: 15px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 15px;
  height: 140px;
}

.sidebar-tip-title {
  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 12px;
  margin-top: 0;
}

.sidebar-tip-subtitle {
  font-weight: 300;
  font-size: 12px;
  line-height: 160%;
  margin-bottom: 15px;
}

.sidebar-tip-btn {
  font-size: 12px !important;
  letter-spacing: 0.04em !important;
  height: 34px;
}

.job-status {
  border-radius: 50%;
  width:35px;
  height:35px; 
}

.job-status-post-element {
  margin-left: 15px;
  display: flex;
  align-items: center;
}

.job-status-post-element > div:first-of-type {
  height: 8px;
  width: 8px;
  background-color: green;
}

.job-status-post-element > span {
  margin-left: 4px; 
}
