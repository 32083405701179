.dropdown-container {
  position: relative;
  display: inline-block;
}

.dropdown-container-content {
  cursor: pointer;
  display: none;
  position: absolute;
  float: right;
  right: 0;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 2;
  overflow: hidden;
  background-color: white;
}

.dropdown-container-content.align-left-edge {
  right: unset;
  left: 0;
}

.dropdown-container-content.show {
  display: block;
}