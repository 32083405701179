.noJobs {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  line-height: 1.6;
}

.noJobs span {
  text-transform: uppercase;
  font-weight: bold;
  margin: 0 0.25em 0 0.25em;
}