.modal:has(.signup) {
  background: linear-gradient(45deg, rgba(247,226,188,1) 0%, rgba(71,46,2,1) 100%);
}

.loader.signup-loader .loader_cogs {
  top: 300px !important;
  bottom: unset;
}

.signup-header {
  grid-area: header;
}

.signup-header .navbar {
  padding-left: 10px;
  padding-right: 10px;
}

.signup-main {
  grid-area: main;
  display: flex;
}

.signup-main > div {
  flex: 1;
}

.signup-main-top {
  color: white;
  background-color: var(--medium-gray);
  text-align: center;
  padding-top: 1px;
  padding-bottom: 1px;
}

.signup-main-top p:first-of-type {
  top: 157px;
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.02em;
  margin-top: 12px;
  margin-bottom: 12px;
}
