.jobs-tiles {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.jobs-tile {
  border-radius: 4px;
  background-color: white;
  box-shadow: 2px 2px 50px rgba(0, 0, 0, 0.05);
  width: 286px;
  cursor: pointer;
}

.jobs-tile:hover {
  outline: var(--medium-gray) 2px solid;
}

.jobs-tile > div:first-of-type {
  padding-left: 25px;
  padding-right: 25px;
}

.jobs-tile p:last-of-type {
  color: var(--medium-gray);
}

.jobs-tile .jobs-applicant {
  justify-content: space-between;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 10px;
  padding-bottom: 15px;
}