.questionnaire-header {
  grid-area: header;
}

.questionnaire-header .navbar {
  background: var(--off-black);
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.08);
}

.questionnaire-main {
  grid-area: main;
  padding-top: 40px;
  padding-left: 120px;
  padding-right: 120px;
  overflow-y: auto;
}
