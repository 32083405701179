@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}

button.custom-btn-grn,
button.custom-btn-wht {
  position: relative;
  overflow: hidden;
  transition: background 400ms;
  background-color: var(--button-grn-background);
  border-color: var(--button-grn-border);
  color: var(--button-grn-text);
  border-radius: 50px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
  outline: none;
  padding: 6px 18px 6px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  user-select: none;
  vertical-align: middle;
  font-family: Roboto;
  letter-spacing: 0.04em;
}

button.custom-btn-wht {
  background-color: var(--button-wht-background);
  border-color: var(--button-wht-border);
  color: var(--button-wht-text);
}

i.ripple-grn,
i.ripple-wht {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 600ms linear;
  background-color: var(--button-grn-ripple);
}

i.ripple-wht {
  background-color: var(--button-wht-ripple);
}

button.custom-btn-grn:hover {
  background-color: var(--button-grn-hover);
  opacity: 1;
}

button.custom-btn-grn:focus {
  background-color: var(--button-grn-focus);
  opacity: 1;
}

button.custom-btn-grn:disabled {
  background-color: var(--button-grn-disabled-background);
  opacity: 0.7;
  color:  var(--button-grn-disabled-text);
  cursor: not-allowed;
}

button.custom-btn-wht:not(:disabled):hover {
  background-color: var(--button-wht-hover);
  opacity: 0.8;
}

button.custom-btn-wht:focus {
  background-color: var(--button-wht-focus);
  opacity: 1;
}
    
button.custom-btn-wht:disabled {
  border-color: rgb(110 112 116 / 18%);
  color: rgb(87 88 91 / 36%);
  cursor: not-allowed;
}