div.upload {
  height: auto;
  /* width: 200px; */
  background-color: var(--ice-blue);
  border: 2px dashed rgb(187, 186, 186);
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  font-size: 16px;
  padding: 10px;
  padding-top: 8px !important;
  padding-bottom: 12px !important;
}

div.upload.drag-over {
  background-color: var(--green-tea);
}

.upload .progress-bar.progress-bar-hidden {
  visibility: hidden;
}

.upload input {
  display: none;
  height: 0;
}

.upload > div > svg {
  height: 64px;
  width: 64px;
  color: var(--app-green);
}

.upload .custom-btn-wht > svg {
  vertical-align: middle;
}