input.date-answer {
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: var(--off-black);
  opacity: 0.8;
}
