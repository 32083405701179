.process-text {
  color: darkslategray;
  align-self: center;
  margin-left: 10px;
  font-size: 18px;
  font-weight: 400;
}

/* For reason unknown, had to specify font-family even though that is covered in index.css*/
::placeholder {
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--placeholder-color);
  opacity: var(--placeholder-opacity);
  line-height: 19px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.04em;
}

/* Removes the "X" */
input[type="search"]::-ms-clear { display: none; width : 0; height: 0; }
input[type="search"]::-ms-reveal { display: none; width : 0; height: 0; }
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

.off-black { color: var(--off-black); }
.forest-green { color: var(--forest-green); }
.medium-gray { color: var(--medium-gray); }

.f-sfd { font-family: 'SF Pro Display'; }

.fw-n { font-weight: normal; }
.fw-3 { font-weight: 300; }
.fw-4 { font-weight: 400; }
.fw-5 { font-weight: 500; }
.fw-b { font-weight: 700; }

.fs-10 { font-size: 10px; }
.fs-12 { font-size: 12px; }
.fs-14 { font-size: 14px; }
.fs-15 { font-size: 15px; }
.fs-16 { font-size: 16px; }
.fs-18 { font-size: 18px; }
.fs-19 { font-size: 19px; }
.fs-20 { font-size: 20px; }
.fs-22 { font-size: 22px; }
.fs-24 { font-size: 24px; }
.fs-26 { font-size: 26px; }
.fs-28 { font-size: 28px; }
.fs-32 { font-size: 32px; }
.fs-36 { font-size: 36px; }
.fs-38 { font-size: 38px; }
.fs-42 { font-size: 42px; }
.fs-44 { font-size: 44px; }
.fs-52 { font-size: 52px; }

.ls-1 { letter-spacing: .01em; }
.ls-2 { letter-spacing: .02em; }
.ls--2 { letter-spacing: -.02em; }
.ls-4 { letter-spacing: .04em; }
.ls-8 { letter-spacing: .08em; }
.ls--4 { letter-spacing: -.04em; }