section.modal-edit-profile {
  width: 678px !important;
  display: flex;
  align-items: stretch;
}

div.edit-profile-left {
  flex: 35%;
  background-color: var(--medium-gray);
}

.modal-edit-profile > form { 
  flex: 65%;
  flex-direction: column;
  letter-spacing: 0.02em;
  justify-content: space-between;
  display: flex;
}

.edit-profile {
  left: 32px;
  position: absolute;
}

.edit-profile-truckerfy-logo {
  top: 88px;
}

.edit-profile-truckerfy-logo svg .donut {
  fill: whitesmoke;
}

/* p.edit-profile {
  color: #FFFFFF;
  font-family: Roboto;
  font-style: normal;
} */

/* p.edit-profile:first-of-type {
  top: 157px;
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.02em;
}

p.edit-profile:last-of-type {
  top: 229px;
  font-size: 14px;
  font-weight: 300;
  line-height: 40px;
  letter-spacing: 0.02em;
} */

.modal-edit-profile table {
  font-family: Roboto;
  margin-top: 50px;
  padding-left: 32px; 
  padding-right: 32px; 
  table-layout: fixed;
  border-spacing:0 10px;
}

.modal-edit-profile th {
  font-weight: 600;
  font-size: 18px;
  padding: 0 0 20px 0;
  letter-spacing: 0.02em;
  text-align: left;
  color: var(--off-black);
}

/* .modal-edit-profile td > span:nth-of-type(1) {
  display: inline-block;
  color: var(--off-black);
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  padding-bottom: 15px;
} */

.modal-edit-profile table tbody tr td .custom-input-container {
  height: 69px;
}

.modal-edit-profile footer {
  display: flex;
  flex-direction: row;
  padding: 0 32px 32px 32px;
  font-size: 14px;
}

.modal-edit-profile footer.footer-info {
  flex: 70% 0;
  display: flex;
  flex-direction: row;
}

.modal-edit-profile footer > :first-child {
  flex: 1;
}

.modal-edit-profile footer > :last-child {
  margin: 10px 0 10px 0;
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
}

.modal-edit-profile .success-message {
  padding-top: 10px;
  padding-bottom: 10px;
  color: var(--app-green);
  text-align: center;
}

.modal-edit-profile .error-message {
  padding-top: 10px;
  padding-bottom: 10px;
  color: darkred;
  text-align: center;
}
