input.integer-answer {
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: var(--off-black);
  opacity: 0.8;
}

.integer-answer-sub-integer-container {
  text-align: right;
}

.integer-answer-sub-text {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  align-items: center;
  color: var(--off-black);
  opacity: 0.8;
}
