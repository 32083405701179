section.modal-confirm-text {
  /* width: 512px !important; */
  display: flex;
  align-items: stretch;
  padding-bottom: 16px;
}

.modal-confirm-text-header {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.modal-confirm-text-header > svg:first-of-type,
.modal-confirm-text-header > svg:last-of-type  {
  align-self: center;
  width: 22px;
  height: 22px;
  color: rgb(248, 186, 71);
  margin-right: 5px;
  margin-top: 29px;
}

.modal-confirm-text-header > svg:last-of-type  {
  margin-left: 5px;
  transform: rotate(45deg);
}

.modal-confirm-text-header > svg:nth-of-type(2)  {
  width: 90px;
  height: 90px;
}

.modal-confirm-text table {
  padding-left: 32px; 
  padding-right: 32px; 
  table-layout: fixed;
  border-spacing: 0 10px;
}

.modal-confirm-text-footer {
  margin-top: 10px;
  display: flex;
}

.modal-confirm-text-footer > button {
  flex: 1;
}