.job-conversation {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
  /* background-color: #F2F2F2; */
}

.job-conversation > section {
  flex: 1;
  padding: 15px;
  overflow: auto;
  /* background-color: rgb(252, 252, 252); */
  background-color: #F2F2F2;
}

.job-conversation > section > table col {
  width: 50%;
}

.job-conversation > footer {
  display: flex;
  flex-direction: column;
}

.job-conversation > footer > div {
  display: flex;
  padding: 15px;
  background-color: #F2F2F2;
}

.job-conversation > footer > div > .textbox {
  flex: 1;
}

.job-conversation > footer > div > .textbox > input {
  height: 48px;
  background-color: white;
}

.job-conversation > footer > div > button {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  align-self: center;
  margin-left: 15px;
}

.job-conversation > footer > div > button > svg {
  margin-left: -11px;
}

.job-conversation-bubble {
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 8px 15px 8px 15px;
  overflow-wrap: anywhere;
  border-radius: 8px;
}

.job-conversation-bubble p {
  margin-top: 10px;
}

.job-conversation-bubble.from-user {
  background-color: var(--green-tea);
}

.job-conversation-bubble-date {
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
}

.job-conversation-bubble-check-time {
  display: flex;
  align-items: flex-end;
  justify-content: right;
}

.job-conversation-bubble-check-time svg {
  width: 18px;
  height: 14.87px;
}

.job-conversation-bubble-check-time.driver svg g {
  fill: var(--medium-gray);
}

.job-conversation-bubble-check-time.carrier svg g {
  fill: var(--forest-green);
}

.job-conversation .loader_cogs {
  position: relative;
  transform: translateY(calc(50vh - 169px));
  top: unset !important;
}

.job-conversation .userName {
  padding-bottom: 4px;
}

.job-conversation .error-message {
  margin-top: 0 !important;
  color: darkred;
  text-align: center;
}
