.carrier-jobs-page-header {
  grid-area: header;
}

.carrier-jobs-page-main {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: var(--hazy-blue);
  grid-area: main;
  overflow: hidden;
}

.carrier-jobs-page-container {
  box-sizing: border-box;
  max-width: 540px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  padding-bottom: 5px;
}

.carrier-jobs-page-content {
  box-shadow: 2px 2px 50px rgba(0, 0, 0, 0.05);
  background-color: white;
  padding-left: 32px;
  padding-right: 32px;
  padding-left: 20px;
  padding-right: 20px;
  overflow-y: auto;
  flex: 1;
}

.carrier-jobs-page .carrier-jobs-page-content {
  padding-bottom: 10px;
}

.carrier-jobs-page-container > p {
  text-align: center;
  margin-top: 18px;
  margin-bottom: 12px;
  padding-left: 5px;
  padding-right: 5px;
}

.carrier-jobs-post-header > div:first-of-type {
  margin-left: -20px;
  margin-right: -20px;
}

.carrier-jobs-post-header-carrier-banner {
  max-width: 100%;
  outline: 1px solid rgba(211, 211, 211, .5)
}

.carrier-jobs-post-header-banner-match {
  display: flex;
}

.carrier-jobs-post-header-carrier-logo {
  margin-top: -51px;
  flex: 1;
  outline: 1px solid rgba(211, 211, 211, .5)
}

.carrier-jobs-post-header-match-container {
  margin-top: -25px;
  margin-left: auto;
  margin-right: -12px;
}

.carrier-jobs-post-header-logo-container {
  height: 145px;
  float: left;
  margin-right: 15px;
}

.carrier-jobs-post-header-logo-container.compact {
  height: 65px;
}

.carrier-jobs-post-header-btn-svg-container {
  margin-top: 18px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.carrier-jobs-post-header-btn-svg-container button {
  z-index: -1;
  /* border-radius: 0; */
  color: var(--off-black);
  flex: 1;
}

.carrier-jobs-post-header-btn-svg-container svg {
  width: 30px;
  height: 30px;
  --svgaccordioncolor: var(--off-black);
  --svgaccordionbackgroundcolor: white;
}

div.divider-light.carrier-jobs-post-divider {
  min-height: 2px;
  max-height: 2px;
  border-color: var(--app-green);
  background-color: var(--app-green);
}

.carrier-jobs-page-badge-container {
  text-align: center;
  margin-top: 5px;
}

.carrier-jobs-page-badge-container > svg {
  height: 55.8px;
  width: unset;
  padding-bottom: 1px;
  cursor: pointer;
}

.carrier-jobs-page-badge-container > img {
  width: 190px;
  margin-left: 15px;
  cursor: pointer;
}

.carrier-job-grid {
  display: grid; 
  grid-template-columns: 30px 1fr 30px 1fr;
  margin-top: 10px;
  margin-bottom: 10px;
  align-items: center;
  row-gap: 4px;
}

.carrier-job-grid svg {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  justify-self: center;
}