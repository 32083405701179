.job-conversations {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.job-conversations-content {
  flex: 1;
  display: grid;
  grid-template-columns: auto 1fr auto;
  overflow: hidden;
  background-color: #F2F2F2;
}

.job-conversations .navbar .nav-content-container {
  padding-left: 0;
}

.job-bar .nav-end-job-conversations {
  display: unset;
}

.job-bar .nav-end-job-conversations > button {
  margin-left: 15px;
}

.job-conversations .searchbox {
  width: 255px;
}

.job-bar-filter-dropdown-menu {
  margin-left: 5px;
  position: relative; /* Necessary to correct position dropdown */
}

.dropdown-menu-component.show-menu.job-bar-filter-container {
  background-color: var(--hazy-blue) !important;
}

.job-bar-filter-container {
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.job-bar-filter-container:hover {
  background-color: var(--hover);
}

.job-bar-filter-container > span {
  font-size: 12px;
}

.job-conversations-drivers-container {
  min-height: 0;
  border-right: 1px solid var(--hazy-blue);
  overflow-y: auto;
  min-width: 0;
  max-width: 435px;
  transition: max-width .4s ease;
}

.job-conversations-drivers-container.shrink {
  max-width: 94px;
}

.job-conversations-drivers {
  max-width: 435px;
  background-color: rgb(252, 252, 252);
  display: flex;
  flex-direction: column;
  padding-top: 2px;
  padding-right: 2px;
  padding-left: 1px;
  gap: 2px;
}

.driver-item {
  left: 0;
  position: sticky;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  background-color: rgb(252, 252, 252);
  box-sizing: border-box;
  overflow: hidden;
}

.driver-item.selected {
  outline: var(--medium-gray) 2px solid;
  background-color: #F2F2F2;
}

.driver-item:not(.selected):hover {
  background-color: var(--hoverSolid);
  outline: rgb(235, 237, 243) 2px solid;
}

.driver-item-images {
  display: flex;
  align-items: center;
  background-color: inherit;
}

.driver-item-images > div:first-of-type {
  width: 63px;
  background-color: inherit;
  z-index: 1;
}

.driver-item-images svg.driver-item-img-chevron {
  margin-right: 9px;
  margin-left: -22px;
  z-index: 0;
  transition: margin .4s ease;
}

.driver-item-images svg.driver-item-img-chevron.show {
  margin-left: 0;
}

.driver-item-img-avatar {
  margin: 20px;
  margin-right: 16px;
}

.driver-item-rows {
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 14px;
  min-width: 0; /* Needed for text truncation to work properly */
  margin-left: 0;
  max-width: 368px;
  width: 368px;
  padding-right: 10px;
  margin-right: unset;
  transition: margin .4s ease;
  z-index: 0;
}

.driver-item-rows.hide {
  margin-left: -368px;
  margin-right: 20px;
  z-index: 0;
}

.driver-item-top-row {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex: 1;
}

.driver-item-top-row > div {
  flex: 1;
  display: flex;
  align-items: self-end;
}

.driver-item-top-row > svg.driver-item-img-chevron {
  margin-top: 3px;
}

.driver-item-message-date {
  text-align: right;
  padding-right: 5px;
}

.driver-item-img-chevron {
  height: 15px;
  transform: rotate(-90deg) translateX(1.5px);
  color: var(--lm-gray);
}


.driver-item-img-check-container {
  float: left;
  margin-top: -1px;
}

.driver-item-img-check {
  width: 20px;
  height: 16.87px;
  margin-right: 2px;
}

.driver-item-img-check.driver g {
  fill: var(--medium-gray);
}

.driver-item-img-check.carrier g {
  fill: var(--forest-green);
}

.driver-item-bottom-row {
  flex: 1;
  padding-right: 48px;
  display: flex;
}

.driver-item-bottom-row > span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

}

.driver-item-bottom-row.from-driver {
  font-style: italic;
}

.job-conversations-container {
  overflow: hidden;
  display: flex;
}

.job-conversations .slideout-content-container {
  padding-left: 0;
  padding-right: 0;
}
