.progress-bar {
  height: 20px;
  width: 100%;
  background-color: #e0e0de;
  border-radius: 50px;
  color: var(--off-black);
  position: relative;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  vertical-align: middle;
}

.progress-bar-indicator {
  position: absolute;
  top: 0;
  height: inherit;
  background-color: var(--forest-green);
  border-radius: inherit;
  overflow: hidden;
  transition: width .1s ease-in-out;
}

.progress-bar-percent {
  color: white;
  width: 0;
}