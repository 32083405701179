.job-page-header {
  grid-area: header;
}

.job-page-main {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: var(--hazy-blue);
  grid-area: main;
  overflow: hidden;
}

.job-page-container {
  box-sizing: border-box;
  max-width: 540px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  padding-bottom: 5px;
}

.job-page-content {
  box-shadow: 2px 2px 50px rgba(0, 0, 0, 0.05);
  background-color: white;
  padding-left: 32px;
  padding-right: 32px;
  padding-left: 20px;
  padding-right: 20px;
  overflow-y: auto;
  flex: 1;
}

.job-page .job-page-content {
  padding-bottom: 10px;
}

.job-page-container > p {
  text-align: center;
  margin-top: 18px;
  margin-bottom: 12px;
  padding-left: 5px;
  padding-right: 5px;
}

.job-page-badge-container {
  text-align: center;
  margin-top: 5px;
}

.job-page-badge-container > svg {
  height: 55.8px;
  width: unset;
  padding-bottom: 1px;
  cursor: pointer;
}

.job-page-badge-container > img {
  width: 190px;
  margin-left: 15px;
  cursor: pointer;
}