.driver-profile .banner-image {
  height: auto; 
  width: 100%;
}

.driver-profile-content > div:first-of-type {
  display: flex;
}

.driver-profile-content > div:first-of-type > div:first-of-type {
  flex: 1;
}

.driver-profile-content .driver-foto {
   margin-top: -51px; 
}

.driver-profile-content .profile-match-ribbon {
  margin-top: -26px; 
  margin-left: auto;
  margin-right: -2px;
}

.driver-profile-content .profile-match-ribbon img {
  width: 170px;
}

.driver-profile-content {
  padding-left: 32px;
  padding-right: 26px;  
}

.driver-profile-drop-down {
  margin-top: 14px; 
}

.driver-profile .driver-profile-drop-down:not(:first-child) {
  margin-top: 24px; 
}

.driver-profile-drop-down-header {
  display: flex;
  flex-direction: row;
  padding-left: 32px;
  padding-right: 32px;
  height: 26px;
}

.driver-profile-drop-down-btn {
  padding: 0;
  background: transparent;
  cursor: pointer;
  border: none;
  outline: none;
  opacity: 0.5;
}

.driver-profile-drop-down-btn svg.caret-down {
  transform: rotate(0deg);
  transition: transform .2s linear;
}

.driver-profile-drop-down-btn svg.caret-up {
  transform: rotateZ(180deg);
  transition: transform .2s linear;
}

.driver-profile-drop-down-btn:hover {
  opacity: 1;
}

.driver-profile-drop-down-btn svg {
  width: 18px;
  height: 18px;
  color: var(--off-black);
  transform: rotateZ(180deg);
}

.driver-profile-drop-down-my-story {
  flex: 1;
}

.driver-profile-drop-down-content {
  margin-left: 40px;
  margin-right: 40px;
  max-height: 5000px;
  overflow: hidden;
  transition: max-height .34s ease-out;
}

.driver-profile-drop-down-content.content-closed {
  max-height: 0;
  transition: max-height .3s ease-in;
}

.driver-profile-drop-down-qa {
  line-height: 12px;
  margin-bottom: 26px;
}

.driver-profile-drop-down-qa > p:not(:first-child) {
  line-height: 16px;
}

svg.read-only-map-answer.driver-post-map-answer {
  width: 100%;
  height: auto;
  margin-top: 15px;
}

.driver-profile-header-text p {
  margin-top: 0;
  margin-bottom: 4px;
}

.driver-profile-header-text p:nth-of-type(1) {
  margin-top: 10px;
}

div.divider-light.driver-profile-divider {
  min-height: 2px;
  max-height: 2px;
  border-color: var(--app-green);
  background-color: var(--app-green);
  margin-left: 32px;
  margin-right: 32px;
}