div.truckerfy-logo {
  display: flex;
  justify-content: center;
  font-family: Sen;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
}

div.truckerfy-logo span {
  line-height: 29px;
  letter-spacing: 0.01em;
}

div.truckerfy-logo svg {
  height: 40px;
  margin-top: 2px;
  margin-right: 5px;
}