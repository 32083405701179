button.state-btn {
  border: 3px solid var(--hazy-blue);
  color: var(--dark-gray);
  font-weight: 300;
  font-size: 12px;
  line-height: 14.06px;
  text-transform: none;
  letter-spacing: 0.01em;
  padding: 10px 1.8rem;
  margin: 5px 10px 5px 0;
}

button.state-btn span:first-child {
  font-weight: 400;
  font-size: 14px;
  line-height: 16.41px;
  letter-spacing: 0.02em;
}

button.state-btn span:nth-child(3) {
  font-weight: 300;
  font-size: 12px;
  line-height: 14.06px;
  letter-spacing: 0.02em;
}

button.state-btn-selected {
  background-color: var(--green-tea) !important;
  border: 3px solid var(--app-green);
  color: var(--app-green);
}

button.state-btn-selected:focus {
  background-color: #d8ede1 !important;
}
