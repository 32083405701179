section.modal-signup {
  width: 720px !important;
  display: flex;
  align-items: stretch;
}

div.signup-left {
  flex: 35%;
  background-color: var(--medium-gray);
}

.modal-signup > form {
  width: 720px;
  flex: 65%;
}

.modal-signup table {
  margin-top: 88px;
  padding-left: 32px; 
  padding-right: 32px; 
}

.signup {
  left: 32px;
  position: absolute;
}

.signup-truckerfy-logo {
  top: 88px;
}

.signup-truckerfy-logo svg .donut {
  fill:whitesmoke;
}

p.signup {
  color: #FFFFFF;
  font-family: Roboto;
  font-style: normal;
}

p.signup:first-of-type {
  top: 157px;
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.02em;
}
