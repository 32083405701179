.questionnaire-section-header {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: var(--off-black);
  margin-bottom: 0 !important;
}

.questionnaire-section-sub-header {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: var(--off-black);
}

.questionnaire-section {
  display: flex;
  flex-grow: 1;
}

.questionnaire-section-column {
  flex: 1;
  padding-right: 30px;
}

.questionnaire-main .error-message {
  padding-top: 10px;
  padding-bottom: 10px;
  color: darkred;
}

.questionnaire-main form footer {
  text-align: right;
  padding-top: 20px;
  padding-bottom: 30px;
}

.questionnaire-main form footer button:not(:first-of-type) {
  margin-left: 20px;
}

.question-answer-validation {
  display: flex;
  align-items: center;
}

.question-answer-validation > img {
  width: 20px;
	border: 1px solid transparent;
	background-color: transparent;
  outline: 0;
  cursor: pointer;
  filter: invert(13%) sepia(26%) saturate(7417%) hue-rotate(348deg) brightness(107%) contrast(123%);
}

.question-answer-validation > span {
  margin-left: 4px;
  margin-top: 1px;
}
